<script>
import trialHelpers from '@/mixins/trialHelpers';
import { mapState } from 'vuex';

export default {
	mixins: [trialHelpers],
	computed: {
		...mapState(['currentRoleLevel'])
	}
};
</script>

<template>
	<div class="">
		<b-alert :show="currentRoleLevel.roleAssociated !== 'party' && trialActive" dismissible>
			<div>
				Welcome! Your trial will remain active until
				{{ dateFormatter(userProfile.trialEnd) }}.
				<b-link class="alert-link" :to="{ name: 'subscription' }"
					>Subscribe</b-link
				>
			</div>
		</b-alert>
		<b-modal
			id="trialExpired"
			v-model="trialExpired"
			title="Trial Expired"
			hide-footer
			hide-header-close
			no-close-on-backdrop
			no-close-on-esc
			data-testid="close-trial-banner"
		>
			<div>Your Free Trial of Divii has ended.</div>
			<p>
				Oops! Your trial has expired. Upgrade to one of our paid plans
				to continue.
				<b-link class="alert-link" :to="{ name: 'subscription' }"
					>Subscribe</b-link
				>
				in order to continue. Alternatively, you can
				<b-link
					target="_blank"
					:to="{
						name: 'preview',
						params: { id: trialAgreementId },
					}"
					>download</b-link
				>
				your file now.
			</p>
		</b-modal>
	</div>
</template>
